<template>
  <div :class="$style.promos">
    <div :class="$style.wrapper">
      <el-button :class="$style.button" type="primary" @click="synchronize()">
        Синхронизировать
      </el-button>
    </div>
    <el-table :data="paymentTypes" stripe>
      <el-table-column
        prop="orderField"
        label="Порядок"
        min-width="90"
      ></el-table-column>
      <el-table-column prop="name" label="Способы оплаты" width="200">
      </el-table-column>
      <el-table-column
        prop="moySkladName"
        label="Название в МойСклад"
        min-width="200"
      >
      </el-table-column>
      <el-table-column
        prop="isActive"
        label="Активный"
        :class-name="$style.tableCellCenter"
        min-width="90"
      >
        <template slot-scope="scope">
          <input
            type="checkbox"
            :checked="scope.row.isActive"
            style="pointer-events: none"
          />
        </template>
      </el-table-column>
      <el-table-column
        prop="isYMPay"
        label="ЮКасса"
        :class-name="$style.tableCellCenter"
        min-width="80"
      >
        <template slot-scope="scope">
          <input
            type="checkbox"
            :checked="scope.row.isYMPay"
            style="pointer-events: none"
          />
        </template>
      </el-table-column>
      <el-table-column
        prop="isMBPay"
        label="Модуль банк"
        :class-name="$style.tableCellCenter"
        min-width="120"
      >
        <template slot-scope="scope">
          <input
            type="checkbox"
            :checked="scope.row.isMBPay"
            style="pointer-events: none"
          />
        </template>
      </el-table-column>
      <el-table-column
        prop="isDefault"
        label="По умолчанию"
        :class-name="$style.tableCellCenter"
        min-width="120"
      >
        <template slot-scope="scope">
          <input
            type="checkbox"
            :checked="scope.row.isDefault"
            style="pointer-events: none"
          />
        </template>
      </el-table-column>
      <el-table-column
        prop="isAvailableForSelfDelivery"
        label="Самовывоз"
        :class-name="$style.tableCellCenter"
        min-width="120"
      >
        <template slot-scope="scope">
          <input
            type="checkbox"
            :checked="scope.row.isAvailableForSelfDelivery"
            style="pointer-events: none"
          />
        </template>
      </el-table-column>
      <el-table-column>
        <template slot-scope="scope">
          <ActionButtons
            :edit-link="'payment-types/' + String(scope.row.id)"
            @delete="remove(scope.row.id)"
          />
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      :class="$style.pagination"
      layout="prev, pager, next"
      :page-size="limit"
      :total="total"
      :current-page.sync="page"
      @current-change="getList"
      background
    >
    </el-pagination>
  </div>
</template>

<script>
import delivery from '@/delivery'
import ActionButtons from '@/components/atoms/ActionsButtons.vue'
import addQueryParamsAddwine from '@/mixins/addQueryParamsAddwine'

export default {
  mixins: [addQueryParamsAddwine(function(params) { this.getList(params); })],
  components: { ActionButtons },
  data() {
    return {
      paymentTypes: [],
      total: 0,
      page: 1,
      limit: 20,
    }
  },
  created() {
    this.getList()
  },
  methods: {
    initializeFromQuery() {
      this.page = parseInt(this.$route.query.page, 10) || 1
      this.paymentTypes = this.$route.query.paymentTypes || []
      this.limit = this.$route.query.limit || 20
    },
    async getList() {
      const loading = this.$loading({
        lock: true,
      })

      const { value, error } =
        await delivery.PaymentServiceCore.PaymentTypesActions.getList({
          limit: this.limit,
          page: this.page,
        })
        this.updatedQueryParams({
          limit: this.limit,
          page: this.page,
        });
      if (error) return
      this.paymentTypes = value.data
      this.total = value.meta.count

      loading.close()
    },
    async synchronize() {
      const res =
        await delivery.PaymentServiceCore.PaymentTypesActions.synchronize()
      if (!res.error) {
        this.getList()
      }
    },
    async remove(id) {
      const res = await delivery.PaymentServiceCore.PaymentTypesActions.delete(
        id,
      )
      if (!res.error) {
        this.getList()
      }
    },
  },
}
</script>

<style lang="scss" module>
.promos {
  padding: 1rem;
  .tableCellCenter {
    text-align: center;
  }
  .wrapper {
    display: flex;
    justify-content: flex-end;
    .button {
      margin-bottom: 1rem;
    }
  }
  .pagination {
    @include pagination;
  }
}
</style>
